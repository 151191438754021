<i18n>
{
  "de": {
    "portfolios": "Portfolios",
    "editButtonLabel": "Benutzer bearbeiten",
    "editUser": "Benutzerdaten bearbeiten",
    "changePassword": "Password ändern",
    "saveButtonLabel": "Speichern",
    "cancelButtonLabel": "Abbrechen",
    "noPortfolios": "Keine Portfolios",
    "addToPortfolio": "Zugriff auf bestehendes Portfolio geben",
    "userViewLabel": "Benutzer-Ansicht",
    "pendingMessage": "Wird gespeichert...",
    "activityTitle": "Aktivität",
    "lastActivitiy": "Letzte Aktivität",
    "lastLogin": "Letztes Login",
    "deleteConfirmation": "Wollen Sie den Zugriff von Portfolio \"{name}\" wirklich entziehen?",
    "userDataTitle": "Benutzerdaten",
    "firstName": "Vorname",
    "lastName": "Nachname",
    "companyName": "Firma",
    "email": "E-Mail Adresse"
  }
}
</i18n>

<template>
  <main-layout :ready="allUsersReady" class="admin-user-page">
    <template #default>
      <p v-if="updatePasswordError">{{ updatePasswordError }}</p>
      <p v-if="removePortfolioError">{{ removePortfolioError }}</p>
      <p v-if="updatePasswordPending || removePortfolioPending">{{ $t('pendingMessage') }}</p>
      <template v-else>
        <h1>{{ user.first_name }} {{ user.last_name }}</h1>
        <div v-if="editPassword">
          <h2>{{ $t('changePassword') }}</h2>
          <form class="password-form" @keydown.esc="onCancel">
            <text-input password :min-length="6" v-model="password" :edit="true" />
            <button-wrapper>
              <button class="button" type="submit" :disabled="submitDisabled" @click.prevent="onSavePassword">{{ $t('saveButtonLabel') }}</button>
              <button class="button" @click.prevent="onCancel">{{ $t('cancelButtonLabel') }}</button>
            </button-wrapper>
          </form>
        </div>
        <div v-else>
          <button-wrapper>
            <a class="button" @click.prevent="onEditUser">{{ $t('editButtonLabel') }}</a>
            <a class="button" @click.prevent="onEditPassword">{{ $t('changePassword') }}</a>
          </button-wrapper>
          <detail-list :title="$t('userDataTitle')" class="user-data-table">
            <detail-list-item :title="$t('firstName')">{{ user.first_name }}</detail-list-item>
            <detail-list-item :title="$t('lastName')">{{ user.last_name }}</detail-list-item>
            <detail-list-item :title="$t('companyName')">{{ user.company_name }}</detail-list-item>
            <detail-list-item :title="$t('email')">{{ user.email }}</detail-list-item>
          </detail-list>
          <detail-list :title="$t('activityTitle')" class="user-data-table">
            <detail-list-item :title="$t('lastActivitiy')"><span v-if="user.last_seen">{{ user.last_seen | luxon({output: "full"}) }}</span><span v-else>-</span></detail-list-item>
            <detail-list-item :title="$t('lastLogin')"><span v-if="user.last_seen">{{ user.current_login_at | luxon({output: "full"}) }}</span><span v-else>-</span></detail-list-item>
          </detail-list>

          <h2>{{ $t('portfolios') }}</h2>
          <ul v-if="hasPortfolios" class="portfolios-list">
            <li v-for="portfolio in user.portfolios" :key="portfolio.id">
              <router-link :to="{ name: 'adminPortfolioDetails', params: { portfolio_id: portfolio.id }}"><strong>{{ portfolio.name }}</strong></router-link>
              <router-link :to="{ name: 'portfolio', params: { portfolio_id: portfolio.id }}">{{ $t('userViewLabel') }}</router-link>
              <span>{{ portfolio.role }}</span>
              <list-button-wrapper>
                <list-delete-button @click="onRemovePortfolio(portfolio)" />
              </list-button-wrapper>
            </li>
          </ul>
          <p v-else>{{ $t('noPortfolios') }}</p>

          <button-wrapper>
            <router-link class="button" :to="{ name: 'adminAddPortfolioToUser', params: { id: user.id } }">{{ $t('addToPortfolio') }}</router-link>
          </button-wrapper>
        </div>
      </template>
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import AdminAllUsersMixin from '@/pages/vuex-mixins/AdminAllUsersMixin'
import AdminUserMixin from '@/pages/vuex-mixins/AdminUserMixin'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton'
import DetailList from '@/components/shared/lists/DetailList'
import DetailListItem from '@/components/shared/lists/DetailListItem'
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import TextInput from '@/components/shared/forms/TextInput'

import { mapActions } from 'vuex'

export default {
  mixins: [
    AdminAllUsersMixin, // Provides: allUsers, allUsersReady
    AdminUserMixin // Provides: user
  ],
  data () {
    return {
      password: '',
      editPassword: false,
      updatePasswordPending: false,
      updatePasswordError: null,
      removePortfolioPending: false,
      removePortfolioError: null
    }
  },
  computed: {
    hasPortfolios () {
      return Boolean(this.user && this.user.portfolios && this.user.portfolios.length)
    },
    submitDisabled () {
      return this.password === null || this.password.length < 6
    }
  },
  methods: {
    ...mapActions({
      updateUserPassword: 'users/updatePasswordById',
      removePortfolioUser: 'portfolio/removePortfolioUser'
    }),
    onEditUser () {
      this.$router.push({ name: 'adminEditUser', params: { user_id: this.user_id } })
    },
    onCancel () {
      this.editPassword = false
      this.password = ''
    },
    async onSavePassword () {
      try {
        this.updatePasswordPending = true
        await this.updateUserPassword({ id: this.user_id, password: this.password })
      } catch (error) {
        this.updatePasswordError = error
      }
      this.updatePasswordPending = false
      this.editPassword = false
    },
    onEditPassword () {
      this.editPassword = true
    },
    async onRemovePortfolio (portfolio) {
      const confirmationText = this.$t('deleteConfirmation', { name: portfolio.name })
      if (confirm(confirmationText)) {
        try {
          this.removePortfolioPending = true
          await this.removePortfolioUser({ portfolioId: portfolio.id, userId: this.user_id })
        } catch (error) {
          this.removePortfolioError = error
        }
        this.removePortfolioPending = false
      }
    }
  },
  components: {
    ListButtonWrapper,
    ListDeleteButton,
    MainLayout,
    ButtonWrapper,
    TextInput,
    DetailListItem,
    DetailList
  }
}
</script>

<style>
.admin-user-page .user-data-table {
  max-width: 600px;
  margin-bottom: var(--spacing-l);
}

.admin-user-page .password-form {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: baseline;
  justify-content: start;
  gap: var(--spacing-l);
  row-gap: var(--spacing-s);
  margin-bottom: var(--spacing-xl);
}

.admin-user-page .password-form button:disabled {
  color: var(--error-color);
  border: var(--box-error-border);
}

.admin-user-page .portfolios-list {
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: var(--spacing-l);
  row-gap: var(--spacing-s);
  justify-content: start;
  align-items: center;
}

.admin-user-page .portfolios-list li {
  display: contents;
}

.admin-user-page .portfolios-list li:first-child::before,
.admin-user-page .portfolios-list li::after {
  content: "";
  grid-column: 1 / -1;
  border-bottom: var(--box-border);
}

.admin-user-page .portfolios-list li > :first-child {
  grid-column-start: 1;
}

.admin-user-page .portfolios-list li h3 {
  margin-bottom: 0 !important;
}

.admin-user-page .portfolios-list li .button-section {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  justify-content: start;
  align-items: baseline;
  gap: var(--spacing-m);
}

.admin-user-page .portfolios-list li .button-section {
  align-self: center;
}

.admin-user-page .portfolios-list li .button-section a {
  opacity: 0.7;
}

.admin-user-page .portfolios-list li .button-section a:hover {
  opacity: 1.0;
}
</style>
