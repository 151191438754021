<!--
AdminUserMixin
Loads a specific user for pages with router argument "user_id"

Provides: user
Requires: AdminAllUsersMixin
-->
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    // Properties set by router:
    user_id: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      __userById: 'users/getById'
    }),
    user () {
      return this.user_id && this.__userById(this.user_id)
    }
  }
}
</script>
